.navigation {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  padding: 0 20px;
  background-color: black;
  color: whitesmoke;
  display: flex;
}

.navigation {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  transition: transform 0.3s ease-in-out;
}
.logo {
  width: 60%;
  display: flex;
}

.logo h2 {
  margin-left: -12px;
  margin-bottom: 0;
  align-items: baseline;
}

.nav-links {
  display: flex;
  justify-content: center;
  padding-right: 10%;
}
.logo img {
  height: 60px;
}

.nav-links ul,
.responsive-nav ul {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

.nav-links a:hover {
  font-weight: bolder;
}

.nav-links li {
  margin: 0 10px;
}

.nav-links a,
.responsive-nav a {
  color: whitesmoke;
  text-decoration: none;
  font-size: 16px;
  font-weight: 500;
}

.hamburger {
  display: none;
  cursor: pointer;
}

.hamburger .line1,
.hamburger .line2,
.hamburger .line3 {
  width: 30px;
  height: 3px;
  background-color: whitesmoke;
  margin: 5px 0;
}

.responsive-nav {
  display: none;
  position: absolute;
  top: 60px;
  right: 0;
  background-color: black;
  width: 100%;
  padding: 20px;
}

.responsive-nav ul {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px;
}

.responsive-nav li {
  margin: 10px 0;
}

@media (max-width: 767px) {
  .nav-links {
    display: none;
  }

  .logo h2 {
    visibility: hidden;
  }
  .hamburger {
    display: block;
  }

  .responsive-nav {
    display: block;
  }
}
