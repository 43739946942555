.projectsSection {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  height: fit-content;
  padding-top: 50px;
  background-color: white;
  /* background: url(../../icons/background/Header1.jpg) no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover; */
}

.projectsIntroContainer {
  display: flex;
  justify-content: center;
  text-align: center;
  flex-wrap: wrap;
  flex-direction: column;
  width: 100%;
  background-color: white;
  filter: invert(1);
}

.projectsHeader {
  font-size: 56px;
  margin-top: 3rem;
  color: #ff5925;
}

.projectsDisclaimer {
  width: 60%;
  font-size: 32px;
  margin: auto;
  margin-bottom: 6rem;
  padding: 10px;
  color: black;
}

.projectsContainer {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 90%;
}

@media (max-width: 768px) {
  .projectsSection {
    flex-direction: column;
    width: 100%;
    align-items: center;
  }
  .projectsIntroContainer {
    width: 100%;
    align-items: center;
    text-align: justify;
  }
  .projectsHeader {
    font-size: 32px;
  }
  .projectsDisclaimer {
    font-size: 24px;
  }
}
