.card {
  display: flex;
  justify-content: center;
  text-align: center;
  flex-wrap: wrap;
  max-width: 20em;
  width: 300px;
  margin: 2em;
  border-radius: 50px;
  border: 4px solid black;
}

.card:hover {
  transform: translateY(-10px);
  box-shadow: 0px 10px 20px #ccc;
}

.githubButton:hover {
  transition: 0.3s;
  transform: scale(1.1);
}
