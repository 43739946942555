.contactMe {
  background-color: whitesmoke;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  filter: invert(1);
}

.contact-me-body {
  width: 100%;
  align-items: center;
  padding-top: 10%;
}

.contactCard {
  width: 600px;
  margin: 2rem auto;
  border: 2px solid black;
  border-radius: 20px;
  box-shadow: 2px 2px 5px black;
  padding: 1rem;
  background-color: whitesmoke;
}

.form-field {
  margin: 1rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: violet;
}

.statusMessage {
  text-align: left;
  font-weight: 500;
  margin-bottom: 0.5rem;
}

input[type="text"],
input[type="email"],
textarea {
  position: relative;
  padding: 0.3rem;
  border-radius: 5px;
  border: 1px solid black;
  font-size: 1rem;
  width: 98%;
  resize: none;
}

button[type="submit"] {
  background-color: black;
  color: white;
  padding: 1rem 1rem;
  border: none;
  border-radius: 5px;
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
  margin-top: 1rem;
  width: 100px;
}

@media only screen and (max-width: 600px) {
  .contactCard {
    width: 90%;
  }
}
