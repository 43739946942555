.skills-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.skills-section {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  width: 90%;
  padding: 50px;
}

.skills-page {
  height: 100%;
  background-color: white;
  align-items: center;
}

.skillheadertitle {
  color: green;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px;
  background-color: white;
  filter: invert(1);
}

.skillheadertitle h1 {
  font-size: 56px;
}
.skillheadertitle p {
  width: 60%;
  font-size: 32px;
  color: black;
  margin: 0;
  padding-bottom: 50px;
}

.skill {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 75px;
  height: 75px;
  margin: 10px;
  padding: 25px;
  border-radius: 50px;
  transition: all 0.3s ease-in-out;
  text-align: center;
  cursor: none;
}

.skill p {
  color: black;
}

@media (max-width: 1024px) {
  .skill {
    width: 150px;
    height: 150px;
    margin: 15px;
  }
}

@media (max-width: 768px) {
  .skill {
    width: 100px;
    height: 100px;
    margin: 10px;
  }
  .skills-page {
    flex-direction: column;
    padding: 0;
  }
  .skillheadertitle h1 {
    padding: 50px;
    font-size: 32px;
  }

  .skillheadertitle p {
    padding: 20px;
    font-size: 24px;
    text-align: justify;
  }

  .skills-section {
    padding: 15px;
  }
}

.skill img {
  width: 80px;
  height: 60px;
  margin-bottom: 20px;
}

.skill:hover {
  transform: translateY(-10px);
  box-shadow: 0px 10px 20px #ccc;
  border: 1px solid black;
}

.skill p {
  font-size: 14px;
  font-weight: bold;
}
